import { onMounted } from "vue";

/**
 * @module compositionAPI
 */

/**
 * Listen to enter key
 *
 * @method
 * @param {function} callback
 * @returns {void}
 */
export const useEnterKey = (callback) => {
  const listenToEnterKey = () => {
    document.addEventListener("keypress", (event) => {
      if (event.key !== "Enter") return;
      callback();
    });
  };

  onMounted(() => {
    listenToEnterKey();
  });
};

export default useEnterKey;
