<template>
  <v-row>
    <v-col cols="12" class="mb-n3">
      <BaseTextField
        v-model="formValue.displayName"
        label="USER_MANAGEMENT.ROLES.FIELD_DISPLAY_NAME"
        required
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RoleForm",

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        name: null,
      }),
    },
  },

  computed: {
    formValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
