<template>
  <ConfirmationDialog
    @confirm="submit"
    confirmLabel="USER_MANAGEMENT.ROLES.EDIT_BUTTON_LABEL_SAVE"
    @close="$emit('reload')"
    :loading="sending"
    :showContent="showContent"
    icon="far fa-pencil"
    showDialogWidth="640px"
    tooltip="USER_MANAGEMENT.ROLES.ACTION_EDIT"
    v-model="showDialog"
    color="primary"
    title="USER_MANAGEMENT.ROLES.ACTION_EDIT_TITLE"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <RoleForm v-model="role" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import RoleForm from "@components/Management/Role/RoleForm.vue";
import ConfirmationDialog from "@components/ConfirmationDialog/";
import formValidation from "@mixins/formValidation";

export default {
  name: "RoleEdit",

  mixins: [formValidation],

  components: {
    RoleForm,
    ConfirmationDialog,
  },

  props: {
    roleId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      showContent: false,
      sending: false,
      role: {
        id: 0,
        name: null,
      },
    };
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      this.loadRole();
    },
  },

  methods: {
    resetRole() {
      this.role = {
        id: 0,
        name: null,
      };
    },

    async loadRole() {
      this.resetRole();

      try {
        const { data } = await this.$axios.userManagement.showRole({ roleId: this.roleId });
        this.role = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.showContent = true;
      }
    },

    async submit() {
      this.validate();

      this.$nextTick(async () => {
        this.sending = true;

        if (this.formValidation) {
          try {
            await this.$axios.userManagement.updateRole({
              roleId: this.role.id,
              displayName: this.role.displayName,
            });

            this.showDialog = false;
            this.$emit("reload");
          } catch (err) {
            console.error(err);
          } finally {
            this.sending = false;
          }
        }
      });
    },
  },
};
</script>
