<template>
  <div>
    <div v-if="!noTrigger">
      <BaseButtonTertiary
        @click="open"
        v-if="tertiary && !icon && !hasButtonSlot"
        :disabled="disabled"
      >
        {{ $t(label) }}
      </BaseButtonTertiary>

      <BaseButton
        @click="open"
        v-if="!tertiary && !icon && !hasButtonSlot"
        :outlined="secondary"
        :disabled="disabled"
        :small="small"
        :iconLeft="!!btnIcon"
        :iconName="btnIcon"
        :color="triggerColor"
      >
        {{ $t(label) }}
      </BaseButton>

      <v-tooltip left v-if="!hasButtonSlot">
        <template #activator="{ on, attrs }">
          <v-btn icon @click="open" v-if="icon" :disabled="disabled" v-bind="attrs" v-on="on">
            <v-icon small>{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t(tooltip) }}</span>
      </v-tooltip>

      <slot name="button" v-if="hasButtonSlot" />
    </div>

    <v-dialog :max-width="dialogWidth" :min-width="dialogWidth" v-model="dialog">
      <v-card class="py-8 px-6">
        <v-card-title class="pa-0">
          <v-row>
            <v-col cols="auto" align-self="center">
              <BaseHeadline size="3">
                {{ $t(title) }}
              </BaseHeadline>
            </v-col>
            <v-spacer />
            <v-col class="text-right" align-self="center" cols="auto" v-if="hideCloseButton">
              <BaseHover v-model="closeHover">
                <v-btn icon @click="close">
                  <v-icon :color="!closeHover ? 'gray400' : 'lowEmphasis'">
                    far fa-times-circle
                  </v-icon>
                </v-btn>
              </BaseHover>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pa-0 mb-8" v-if="showContent">
          <div class="body-1 mt-3" v-html="$t(body)" v-if="slotIsEmpty"></div>
          <div class="mt-3" v-if="!slotIsEmpty">
            <slot />
          </div>
        </v-card-text>

        <v-card-text class="pa-0 mb-8" v-else>
          <div class="body-1 mt-3">
            <v-progress-circular indeterminate color="primary" />
          </div>
        </v-card-text>

        <v-card-actions class="pa-0">
          <v-row no-gutters>
            <v-spacer />
            <v-col cols="auto" align-self="center" class="mr-4" v-if="!hideCloseButton">
              <BaseButtonTertiary small @click="close">
                {{ $t(labelCancel) }}
              </BaseButtonTertiary>
            </v-col>

            <v-col cols="auto" align-self="center" class="mr-4" v-if="addButton">
              <BaseButton small @click="addEvent" :color="addColor" :disabled="disableAddButton">
                {{ $t(addLabel) }}
              </BaseButton>
            </v-col>

            <v-col cols="auto" align-self="center">
              <BaseButton
                small
                @click="confirm"
                :color="color"
                :loading="loading"
                :disabled="disableConfirm"
              >
                {{ $t(labelConfirm) }}
              </BaseButton>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import useEnterKey from "@/use/useEnterKey";

export default {
  name: "ConfirmationDialog",

  props: {
    title: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
    },

    body: {
      type: String,
      required: false,
    },

    labelCancel: {
      type: String,
      required: false,
      default: "CONFIRMATION_DIALOG.BUTTON_LABEL_CANCEL",
    },

    labelConfirm: {
      type: String,
      required: false,
      default: "CONFIRMATION_DIALOG.BUTTON_LABEL_OK",
    },

    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },

    tertiary: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    showContent: {
      type: Boolean,
      required: false,
      default: true,
    },

    value: {
      type: Boolean,
      required: true,
      default: false,
    },

    color: {
      type: String,
      required: false,
      default: "secondary",
    },

    icon: {
      type: String,
      required: false,
      default: null,
    },

    addButton: {
      type: Boolean,
      require: false,
      default: false,
    },

    addColor: {
      type: String,
      required: false,
      default: "primary",
    },

    addLabel: {
      type: String,
      required: false,
    },

    hideCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    dialogWidth: {
      type: String,
      required: false,
      default: "470",
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableConfirm: {
      type: Boolean,
      required: false,
      default: false,
    },

    disableAddButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    tooltip: {
      type: String,
      required: false,
    },

    noTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    confirmWithEnter: {
      type: Boolean,
      required: false,
      default: true,
    },

    btnIcon: {
      type: String,
      required: false,
      default: null,
    },

    triggerColor: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  data() {
    return {
      dialog: false,
      closeHover: false,
    };
  },

  computed: {
    slotIsEmpty() {
      return !this.$slots.default;
    },

    hasButtonSlot() {
      return !!this.$slots.button;
    },
  },

  methods: {
    open() {
      this.dialog = true;
      this.$emit("open");
    },

    close() {
      this.dialog = false;
      this.$emit("cancel");
    },

    confirm() {
      this.$emit("confirm");
    },

    addEvent() {
      this.$emit("addEvent");
    },
  },

  setup(props, { emit }) {
    useEnterKey(() => {
      if (!props.value || !props.confirmWithEnter) return;
      emit("confirm");
    });
  },

  watch: {
    value(value) {
      this.dialog = value;
    },

    dialog(value) {
      this.$emit("input", value);

      if (!value) {
        this.$emit("close");
      } else {
        this.$emit("open");
      }
    },
  },
};
</script>
